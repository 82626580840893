import React, { useState, useEffect, useCallback } from 'react'
import { graphql } from 'gatsby'
import { Container, Spinner } from 'theme-ui'
import Layout from '@ui-layout/Layout'
import Seo from '@ui-components/Seo'
import Divider from '@ui-components/Divider'
import ModalWithTabs from '@ui-blocks/Modal/Block01'
import ModalSimple from '@ui-blocks/Modal/Block02'
import Header from '@ui-blocks/Header/Block01'
import Hero from '@ui-blocks/Hero/Block02'
import Services from '@ui-blocks/FeaturesWithPhoto/Block02'
import Footer from '@ui-blocks/Footer/Block01'
import Contact from '@ui-blocks/CallToAction/Block02'
import { normalizeBlockContentNodes, converDate } from '@blocks-helpers'
import styles from './_styles'
import Reveal from '@ui-components/Reveal'
import { injectIntl } from 'gatsby-plugin-intl'
import ModalPrivacy from '@ui-blocks/Modal/Block03'

const OurDna = props => {
  const { allBlockContent } = props.data
  const { locale } = props.intl
  const [content, setContent] = useState(
    normalizeBlockContentNodes(allBlockContent?.nodes)
  )
  const [loder, setLoder] = useState(false)

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.async = true;
    script.type = "text/javascript";
    script.setAttribute("id", "Cookiebot");
    script.setAttribute("data-cbid", "b4c6ff2f-3209-4e15-a3a7-1840c7fd5c02");
    script.setAttribute("data-blockingmode", "auto");
    document.body.appendChild(script);
  }, []);

  const getValues = useCallback(() => {
    setLoder(true)
    fetch(`https://crossjourney.org/api/Page?name=about/our-dna&lang=${locale}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        converDate(data).then((datas) => {
          setContent(datas);
          // console.log('data', datas, normalizeBlockContentNodes(allBlockContent?.nodes));
        });
      })
      .catch(error => {
        console.log('error', error)
      })
      .finally(() => setLoder(false))
  }, [locale])

  useEffect(() => {
    getValues()
  }, [getValues])

  if (!content || loder)
    return (
      <Reveal effect='fadeInDown'>
        <Spinner size='64' color='alpha' />
      </Reveal>
    )

  return (
    <Layout {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalSimple content={content['search']} />
      <ModalPrivacy content={content['privacyNotice']} />
      <ModalPrivacy content={content['termsUse']} />
      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
        <Divider space='4' />
        <Container variant='cards.paper-lg' sx={styles.servicesContainer}>
          <Services content={content['services']} />
        </Container>
      </Container>
      <Divider space='4' />
      <Contact content={content['cta']} />
      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query aboutOurDnaBlockContent {
    allBlockContent(
      filter: { page: { in: ["about/our-dna", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default injectIntl(OurDna)
